export const opcionEscogidaSimple = (producto) => {
    if(producto.opciones){
        if(Array.isArray(producto.opciones)){
            if(producto.opciones.length > 0){
                const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                if(encontrar_seleccion > -1){
                    return producto.opciones[encontrar_seleccion].titulo
                }
            }
        }
    }
    return false
}

export const calcularPrecioFinal = (producto) => {
    let precio_final = producto.precio ? producto.precio : 0
    if(producto.precio_oferta) precio_final = producto.precio_oferta
    let cantidad = producto.cantidad ? producto.cantidad : 1
    precio_final = precio_final * cantidad
    // VER OPCIONES
    if(producto.opciones){
        if(Array.isArray(producto.opciones)){
            if(producto.opciones.length > 0){
                precio_final = 0
                const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                if(encontrar_seleccion > -1){
                    const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                    if(isNaN(precio_opcion_seleccionada) !== true){
                        precio_final = precio_opcion_seleccionada * cantidad
                    }
                }
            }
        }
    }

    // VER ADICIONALES
    if(producto.adicionales){
        if(Array.isArray(producto.adicionales)){
            if(producto.adicionales.length > 0){
                const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                    if(!op.opciones) return false
                    if(Array.isArray(op.opciones) !== true) return false
                    if(op.opciones.length < 1) return false
                    return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                })
                for(const adicional of encontrar_adicionales_seleccionados){
                    for( const opcion of adicional.opciones ){
                        if(opcion.seleccionado !== true) continue
                        if(isNaN(opcion.precio) !== true){
                            precio_final = precio_final + opcion.precio      
                        }
                    }
                }
            }
        }
    }

    return precio_final
}

export const calcularPrecioFinalDinamico = (producto) => {
    if(!producto.categorias) return 0
    if(Array.isArray(producto.categorias) !== true) return 0
    if(producto.categorias.length < 1) return 0
    const calcular = producto.categorias.reduce((prev,next) => {
        if(!next.opciones) return prev
        if(Array.isArray(next.opciones) !== true) return prev
        if(next.opciones.length < 1) return prev
        let total_actual = 0
        const seleccionados = next.opciones.filter(o => o.selected === true)
        for( const op of seleccionados ){
            const price = parseInt(op.precio)
            if(!isNaN(price)){
                total_actual = total_actual + price
            }
        }
        return prev + total_actual
    }, 0)
    return calcular
}