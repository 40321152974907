import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Tooltip, OverlayTrigger, Container } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import 'moment/locale/es';
import data, { async_storage_key } from '../../lib/backend/data'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import socket from '../../lib/websockets'
import { calcularPrecioFinal, opcionEscogidaSimple } from '../../lib/helpers/pedidos/pedidos'
import Badge from 'react-bootstrap/Badge'
import Modal from 'react-bootstrap/Modal'
import BoxProducto from '../../subComponents/comandas/box_producto'
import { validarHorario, validateEmail } from '../../lib/helpers/helpers'
import { confirmAlert } from 'react-confirm-alert'
import { ImprimirTicket } from './imprimir_ticket'
import ReactToPrint from 'react-to-print'
import CajasSelector from '../../subComponents/vendedores/selector'


const pageStyle = `

@media print {
  .page-break {
    page-break-before: always;
  }
  h4, div, h6 {
    font-size: 45px !important;
  }
  p {
    font-size: 30px !important;
  }
  svg {
    width: 60%;
    height: auto;
    display: block;
  }
}  
`;

class Mesas extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(async_storage_key)),
            pedidos: [],
            establecimientos: [],
            establecimiento_seleccionado: false,
            establecimiento: false,
            tipo_visualizacion: 'estados',
            pedido_seleccionado: false,
            modal_pedido_seleccionado: false,
            loading_accion_pedido: false,
            tipo_visualizacion_pedido: 'resumen',
            mesas: [],
            loadingMesas: true,
            loadingMaster: true,
            color: '#393939',
            mesa_cerrar: false,
            loadingMediosPagoActivos: true,
            tipos_pago_activos: [],
            facturando: false,
            id_establecimiento_local_storage: localStorage.getItem('id_establecimiento')
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeMetodoPago = this.handleChangeMetodoPago.bind(this)

    }

    handleChangeMetodoPago(e){
        const { name, value } = e.target
        const { mesa_cerrar } = this.state
        mesa_cerrar[name] = value
        return this.setState({ mesa_cerrar })
    }
    handleChange(e){
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    async componentDidMount(){
        const { user, id_establecimiento_local_storage } = this.state
        if(!id_establecimiento_local_storage){
            alert("Vuelve a la página principal y selecciona el establecimiento primero")
            return setTimeout(() => {
                return window.location.href = "/"
            }, 1000);
        }
        this.obtenerInformacion(user.id_cuenta)
        this.getPedidos(id_establecimiento_local_storage)
        this.getMesas(id_establecimiento_local_storage)
        this.websocketsFunciones(`nueva_comanda_${id_establecimiento_local_storage}`)
        this.getMediosPagoActivos(id_establecimiento_local_storage)
    }

    async getMediosPagoActivos(id_establecimiento){
        const { user } = this.state
        const loading = 'loadingMediosPagoActivos'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mediospago/activos?idestablecimiento=${id_establecimiento}`)
        .then(res => res.json())
        .then(async res => {
            this.setState({ tipos_pago_activos: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    async obtenerInformacion(id){
        const { id_establecimiento_local_storage } = this.state
        await this.obtenerDetallesEstablecimiento(id_establecimiento_local_storage)
    }

    verificarMenuDefault(res) {
        return res.menus.length === 1 ? res.menus[0] : false
    }

    obtenerDetallesEstablecimiento(id){
        const { color, user } = this.state
        this.setState({ loadingMaster: true })
        return fetch(`${data.urlapi}/api/establecimientos/details-complete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('No se pudo obtener el registro')
                return this.setState({ loadingMaster: false })
            }
            const abierto = validarHorario(res.establecimiento)
            let carta_seleccionada = this.verificarMenuDefault(res)
            console.log(carta_seleccionada)
            this.setState({ loadingMaster: false, establecimiento: res, modal_cerrado: abierto === true ? false : true, color: res.establecimiento.color ? res.establecimiento.color : color, carta_seleccionada, abierto })
        })
        .catch(error => {
            this.setState({ loadingMaster: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    getMesas(idestablecimiento){
        const { user } = this.state
        const loading = "loadingMesas"
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/api/mesas/list?id_restaurant=${idestablecimiento}`)
        .then(res => res.json())
        .then(res => {
            this.setState({ [loading]: false, mesas: res })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    websocketsFunciones(establecimiento_seleccionado){
        const { user } = this.state
        socket.on(establecimiento_seleccionado, async pedido => {
            const { pedidos } = this.state
            toast.info('Nueva comanda',{
                closeOnClick: true
            })
            pedidos.push(pedido)
            return this.setState({ pedidos })
        })
        socket.on(`status_${this.state.id_establecimiento_local_storage}`, async datos => {
            const { pedidos } = this.state
            const { id, status } = datos
            const i = pedidos.findIndex(pe => pe._id === id)
                if(i > -1){
                    if(status === 10){
                        pedidos.splice(i,1)
                    } else {
                        pedidos[i].status = status
                    }
                    this.setState({ pedidos })
                }
        })
    }

    getPedidos(idestablecimiento){
        const { user } = this.state
        this.setState({ loadingPedidos: true })
        return fetch(`${data.urlapi}/api/comandas/list?idestablecimiento=${idestablecimiento}`)
        .then(res => res.json())
        .then(res => {
            const pedidos = res.sort((a,b)=> {
                if(a.createdAt > b.createdAt )  return 1
                if(a.createdAt < b.createdAt )  return -1
                return 0
            })
            this.setState({ loadingPedidos: false, pedidos })
        })
        .catch(error => {
            this.setState({ loadingPedidos: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleClose(){
        return this.setState({ modal_pedido_seleccionado: false, mesa_cerrar: false, tipo_visualizacion_pedido: 'resumen' })
    }

    mostrarProductosAgrupados(producto){
        if(!["compuesto","compuesto-dinamico"].includes(producto.tipo)) return false
        if(producto.categorias){
            if(Array.isArray(producto.categorias)){
                if(producto.categorias.length > 0){

                    let mostrar_productos = []

                    for( const cat of producto.categorias ){

                        if(!cat.opciones) continue
                        if(Array.isArray(cat.opciones) !== true) continue
                        if(cat.opciones.length < 1) continue
            
                        const seleccionados = cat.opciones.filter(o => o.selected === true)
                        mostrar_productos = [...mostrar_productos, ...seleccionados]
                    }

                    return <div>
                        <h6 className='mb-0'>Selección</h6>
                        {
                            mostrar_productos.map((pro,ip) => {
                                return <p className='mb-0' key={`pro-agrupado-${ip}`}>{pro.titulo} <b>{pro.sku ? pro.sku : "SIN SKU"}</b></p>
                            })
                        }
                    </div>
                }
            }
        }
        return false
    }

    showByVisualizacionPedido(pedido_seleccionado, total, propina, calcular_total){
        const { tipo_visualizacion_pedido } = this.state
        let nombre_garzon = ''
        if(pedido_seleccionado.user){
            if(typeof pedido_seleccionado.user === "object"){
                if(pedido_seleccionado.user.nombres) nombre_garzon = pedido_seleccionado.user.nombres
            }
        }    
        if(tipo_visualizacion_pedido === 'resumen') return <div>
            <Row>
                            <Col xs={8}>
                            <h6>Total</h6>
                            </Col>
                            <Col xs={4} className='text-right'>
                            ${Intl.NumberFormat("es-ES").format(total)}   
                            </Col>
                        </Row>
                        <hr  />
                        <span>Por: {nombre_garzon}</span>
                        <hr />
                            {
                                pedido_seleccionado.productos.map((pro,i) => {
                                    return <Row key={`pro-${i}`}>
                                        <Col xs={12}>
                                            <img src={pro.imagen} alt={pro.titulo} style={{ width:'50%' }} />
                                            <h5 className='mb-0'>{pro.titulo} <span className='text-danger'>X{pro.cantidad}</span> </h5>
                                            {opcionEscogidaSimple(pro)}
                                            <div>
                                                {this.visualizarAdicionales(pro)}
                                                {this.mostrarProductosAgrupados(pro)}
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <h5 className='mb-0 text-secondary'>${Intl.NumberFormat("es-ES").format(pro.precio_final)}</h5>
                                        </Col>
                                        {pedido_seleccionado.productos.length - 1 === i ? false : <Col xs={12} ><hr /></Col> }
                                    </Row>
                                })
                            }
                            
        </div>
    }

    modalPedidoSeleccionado(){
        const { modal_pedido_seleccionado, pedido_seleccionado } = this.state
        if(!pedido_seleccionado) return false
        let calcular_total = pedido_seleccionado.productos.reduce((prev, next) => prev + calcularPrecioFinal(next), 0)

        let total = calcular_total

        const propina = pedido_seleccionado.propina ? pedido_seleccionado.propina : 0
        if(propina) total = total + propina


        return <Modal show={modal_pedido_seleccionado} onHide={()=>this.handleClose()} centered >
        <Modal.Body>
            {this.showByVisualizacionPedido(pedido_seleccionado, total, propina, calcular_total)}
                            
        </Modal.Body>
      </Modal>
    }

    cambiarEstadoPedido(id,status,reconfirmar){
        if(reconfirmar === true){
            return confirmAlert({
                title: 'Confirmar',
                message: `Estás a punto de generar ejecutar esta acción`,
                buttons: [
                  {
                    label: 'CONFIRMAR',
                    onClick: () => this.cambiarEstadoPedido(id,status)
                  },
                  {
                    label: 'CANCELAR',
                    onClick: () => false
                  },
                ],
              })
        }
        const { pedidos, user } = this.state
        this.setState({ loading_accion_pedido: true })
        return fetch(`${data.urlapi}/api/comandas/cambiarstatus`,{
            method:'POST',
            body: JSON.stringify( {
                id,
                status
            } ),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(res._id){ 
                const i = pedidos.findIndex(pe => pe._id === id)
                if(i > -1){
                    if(status === 10){
                        pedidos.splice(i,1)
                    } else {
                        pedidos[i].status = status
                    }
                    this.setState({ modal_pedido_seleccionado: false, pedidos })
                }
                toast.success('Realizado exitosamente')
            }
            this.setState({ loading_accion_pedido: false })
        })
        .catch(error => {
            this.setState({ loading_accion_pedido: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    mostrarOpcionEscogida(producto){
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        return <p className='mb-0'>{producto.opciones[encontrar_seleccion].titulo}</p>
                    }
                }
            }
        }
        return false
    }

    visualizarAdicionales(producto){
        let escogidos = []
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            escogidos.push(opcion.titulo)
                        }
                    }
                }
            }
        }
        return escogidos.length > 0 ? <div>
        <h5 className="mb-0">Adicionales</h5>
        {
            escogidos.map((item,i) => <span className='spanguia mr-2' style={{ background: '#665b5b', padding: "2px 8px", color: 'white', borderRadius: 4, fontSize: 13 }} key={`item-${i}`}>{item}</span>)
        }
    </div> : false
    }

    calcularPrecioFinal(producto){
        let precio_final = producto.precio ? producto.precio : 0
        let cantidad = producto.cantidad ? producto.cantidad : 1
        precio_final = precio_final * cantidad
        // VER OPCIONES
        if(producto.opciones){
            if(Array.isArray(producto.opciones)){
                if(producto.opciones.length > 0){
                    precio_final = 0
                    const encontrar_seleccion = producto.opciones.findIndex(op => op.seleccionado === true)
                    if(encontrar_seleccion > -1){
                        const precio_opcion_seleccionada = producto.opciones[encontrar_seleccion].precio
                        if(isNaN(precio_opcion_seleccionada) !== true){
                            precio_final = precio_opcion_seleccionada * cantidad
                        }
                    }
                }
            }
        }
    
        // VER ADICIONALES
        if(producto.adicionales){
            if(Array.isArray(producto.adicionales)){
                if(producto.adicionales.length > 0){
                    const encontrar_adicionales_seleccionados = producto.adicionales.filter(op => {
                        if(!op.opciones) return false
                        if(Array.isArray(op.opciones) !== true) return false
                        if(op.opciones.length < 1) return false
                        return op.opciones.filter(ad => ad.seleccionado === true).length > 0
                    })
                    for(const adicional of encontrar_adicionales_seleccionados){
                        for( const opcion of adicional.opciones ){
                            if(opcion.seleccionado !== true) continue
                            if(isNaN(opcion.precio) !== true){
                                precio_final = precio_final + opcion.precio      
                            }
                        }
                    }
                }
            }
        }
    
        return precio_final
    }

    ifTipoPagoChecked(tipopago, pedido){
        if(pedido.tipo_pago === tipopago) return <i className="text-success fas fa-check-square"></i>
        return <i className="far fa-square"></i>
    }

    contadorIcon(array){
        if(array.length < 1) return false
        return <Badge className='bg-danger text-light' >{array.length}</Badge>
    }

    opcionesPedidos(){
        const { pedidos } = this.state
        return <Row className='mb-4'>
            <Col md={3} className="text-center mb-3">
                <Card className='p-3'>
                <h6  >En espera {this.contadorIcon(pedidos.filter(pe => pe.status === 0))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===0))}
                </Card>
            </Col>
            <Col md={3} className="text-center">
                <Card className='p-3'>
                <h6 >En cocina {this.contadorIcon(pedidos.filter(pe => pe.status === 1))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===1))}
                </Card>
            </Col>
            <Col md={3} className="text-center mb-3">
                <Card className='p-3'>
                <h6 >Listo para servir {this.contadorIcon(pedidos.filter(pe => pe.status === 2))}</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===2))}
                </Card>
            </Col>
            <Col md={3} className="text-center mb-3">
                <Card className='p-3'>
                <h6  >Servido</h6>
                <hr />
                {this.visualizarPedidos(pedidos.filter(pe=>pe.status===3))}
                </Card>
            </Col>
            
        </Row>
    }
    
    showByTipoVisualizacion(){
        const { tipo_visualizacion } = this.state
        switch (tipo_visualizacion) {
            case "estados":
                return this.opcionesPedidos()
            case "mesas":
                return this.mostrarVistaMesas()
            default:
                break;
        }
        return false
    }

    solicitarCerrarMesa(facturar){
        const { mesa_cerrar } = this.state
        return confirmAlert({
            title: 'Confirmar',
            message: `Estás a punto de cerrar la cuenta de una mesa, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.cerrarMesa(mesa_cerrar._id, facturar)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              },
            ],
          })
    }

    cerrarMesa(id_mesa, facturar) {
        const { pedidos, mesa_cerrar, user } = this.state
        console.log(mesa_cerrar)
        if(!mesa_cerrar.tipo_pago) return toast.error("Selecciona un método de pago")
        if(!mesa_cerrar.id_vendedor) return toast.error("Selecciona un vendedor")
        let email = ''

        if(mesa_cerrar.email_cliente){
            if(!validateEmail(mesa_cerrar.email_cliente)){
                return toast.error("Email inválido")
            } else {
                email = mesa_cerrar.email_cliente
            }
        }

        const pedidos_eliminar = pedidos.filter(p => p.mesa_seleccionada._id === id_mesa)
        const ids_pedidos_eliminar = pedidos_eliminar.map(p => p._id)
        const loading = "facturando"
        this.setState({ [loading]:true })
        return fetch(`${data.urlapi}/api/mesas/cerrar`,{
            method:'POST',
            body: JSON.stringify( {
                ids_pedidos_eliminar,
                id_mesa,
                facturar,
                tipo_pago: mesa_cerrar.tipo_pago,
                id_vendedor: mesa_cerrar.id_vendedor,
                email
            } ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user._id}`
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.error("Error al realizar la operación")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.status === 200){
                this.setState({ pedidos: pedidos.filter(p => p.mesa_seleccionada._id !== id_mesa) })
                if(res.id_defontana){
                    toast.info(`Generado el folio ${res.id_defontana}`, { autoClose: false })
                    mesa_cerrar.facturado = true
                    //mesa_cerrar.defontana = res.defontana
                    mesa_cerrar.defontana = {
                        id: res.id_defontana,
                        fecha: res.defontana.fecha,
                        garzon: user.nombres,
                        pdf: res.defontana.pdfBase64
                    }
                }
            }
            this.setState({ [loading]: false, mesa_cerrar })
        })
        .catch(error => {
            this.setState({ [loading]:true })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    openModalCerrarMesa(mesa_cerrar){
        return this.setState({ mesa_cerrar })
    }

    mostrarVistaMesas(){
        const { mesas, pedidos } = this.state

        return <Row className='mb-4'>
            {
                mesas.map(mesa => {

                    const pedidos_filtrados = pedidos.filter(pe=>pe.mesa_seleccionada._id===mesa._id)
                    if(pedidos_filtrados.length < 1) return false
                    const calcular_total = pedidos_filtrados.reduce((prev,next) => {
                        const calcular_total_productos = next.productos.reduce((ant,des) => {
                            return ant + des.precio_final
                        }, 0)
                        return prev + calcular_total_productos
                    }, 0)
                    return <Col md={4} className="text-center mb-4" key={mesa._id}>
                    <Card className='p-3'>
                    <h3>MESA {mesa.id} · <b className='text-success'>${Intl.NumberFormat("es-ES").format(calcular_total)}</b></h3>
                    {
                        pedidos_filtrados.length > 0 ? <div>
                            <Button size="sm" style={{ backgroundColor: 'black', border: 'none' }} onClick={() => this.openModalCerrarMesa(mesa)} >CERRAR MESA</Button>
                        </div> : false
                    }
                    
                    <hr />
                    {this.visualizarPedidos(pedidos_filtrados)}
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    bgByTipo(tipo){
        if(tipo==='pendientes') return { bg: 'danger', text:'text-light'}
        return { bg: false, text: false }
    }

    accionesComanda(pedido){
        switch (pedido.status) {
            case 0:
                return <div>
                    <Button variant="link" className='text-success m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 1)}>ENVIAR A COCINA</Button>
                    <Button variant="link" className='text-danger m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 10, true)}>RECHAZAR</Button>
                </div>
            case 1:
                return <div>
                    <Button variant="link" className='text-success m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 2)}>LISTO PARA SERVIR</Button>
                    <Button variant="link" className='text-danger m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 10, true)}>ELIMINAR</Button>
                </div>
            case 2:
                return <div>
                    <Button variant="link" className='text-success m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 3)}>SERVIDO</Button>
                    <Button variant="link" className='text-danger m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 10, true)}>ELIMINAR</Button>
                </div>
            case 3:
                return <div>
                    <Button variant="link" className='text-danger m-0 p-1' onClick={() => this.cambiarEstadoPedido(pedido._id, 10, true)}>ELIMINAR</Button>
                </div>
            default:
                break;
        }
    }

    visualizarPedidos(pedidos,tipo){

        if(pedidos.length < 1) return <div >
            <h5>Sin comandas</h5>
            <p>No tienes pedidos en esta sección</p>
        </div>
        
        return <Row>
            <Col xs={12}>
                <h4 className='mb-4'>{pedidos.length} Comandas</h4>
            </Col>
            {
                pedidos.map((pedido,i) => {  
                    let numero_mesa = ''
                    let nombre_garzon = ''
                    if(pedido.user){
                        if(typeof pedido.user === "object"){
                            if(pedido.user.nombres) nombre_garzon = pedido.user.nombres
                        }
                    }                  
                    if(pedido.mesa_seleccionada){
                        if(typeof pedido.mesa_seleccionada === "object"){
                            if(pedido.mesa_seleccionada.id) numero_mesa = pedido.mesa_seleccionada.id
                        }
                    }                  
                    return <Col xs={12} className="mb-3 hover" key={`pedido-${i}`} >
                        <Card>
                            <Card.Body className='text-left'>
                                <div onClick={()=>this.setState({ pedido_seleccionado: pedido, modal_pedido_seleccionado: true })}>
                                <h4 className='mb-0' style={{ verticalAlign: 'middle' }}>MESA {numero_mesa} <h6 className='mb-1' style={{ background: '#665b5b', display: 'inline-block', padding: "2px 8px", color: 'white', borderRadius: 4, verticalAlign: 'middle' }}><Moment locale="ES" fromNow>{pedido.createdAt}</Moment></h6></h4>
                                <span>Por: {nombre_garzon}</span>
                                <hr />
                                {
                                    pedido.productos.map((pro,i) => {
                                        return <div key={`${pedido._id}-${i}`}>
                                            <BoxProducto producto={pro} />
                                            {pedido.productos.length - 1 === i ? false : <hr /> }
                                        </div>
                                    })
                                }
                            </div>
                            <hr />
                            {this.accionesComanda(pedido)}
                            </Card.Body>
                        </Card>
                    </Col>
                })
            }
        </Row>
    }

    mostrarPedidos(){
        const { loadingPedidos } = this.state

        if(loadingPedidos){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }
        return <div>
            {this.showByTipoVisualizacion()}
        </div>

    }

    mostrarEstablecimientos(){
        const { loadingEmails, establecimientos } = this.state

        if(loadingEmails){
            return <div>
                <Spinner animation='grow' />
                <h3>Cargando información</h3>
            </div>
        }

        return <Card style={{ width: '100%' }} className="mb-3">
        <Card.Body>
        

          <Row>

            <Col md={3}>
            <label className='form-control-label'>Establecimiento</label>
            <select className='form-control' >
                {
                    establecimientos.map((est,i) => {
                        return <option key={`op-${i}`} value={est._id}>{est.titulo}</option>
                    })
                }
            </select>
            </Col>          
          </Row>          

          

        </Card.Body>
      </Card>
    }

    eliminarLista(id){
        const { listas, user } = this.state
        this.setState({ eliminando: true })
        return fetch(`${data.urlapi}/api/listas/delete?id=${id}`)
        .then(res => res.json())
        .then(res => {
            const i = listas.findIndex(li => li._id === id)
            if(i > -1){
                listas.splice(i,1)
                toast.success('Eliminada exitosamente', this.state.toaststyle)
                this.setState({ listas })
            }
            this.setState({ eliminando: false })
        })
        .catch(error => {
            console.log(error)
            this.setState({ eliminando: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })

    }

    mostrarDatosListas(datos, filtro, input){
        const { eliminando } = this.state
        const lowercasedFilter = filtro.toLowerCase();
        const listasFiltradas = datos.filter(item => {
            return Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedFilter)
            );
        });

        return <div>
<h4>{datos.length} Planes</h4>
<p>Elige los planes que desees</p>

{filtro !== ''  ? <Col xs={12}><label className="form-control-label">{listasFiltradas.length} Coincicencias</label></Col>:false}
<Row>
{ listasFiltradas.map((lista,i)=>{
        return <Col md={3} key={`user${i}`}>
            <Card >
            <Card.Img variant="top" src={lista.image} />
        <Card.Body>
            <h1 className="mb-0">{lista.title}</h1>
            <p className="mb-0">{lista.description}</p>
            <h3 >CLP ${Intl.NumberFormat("es-ES").format(lista.price)} <p className="mb-0" style={{ fontSize: 13, display: 'inline-block' }}>{lista.frecuency}</p></h3> 
            { eliminando ? <Spinner animation='grow' /> : this.botonSeleccionar(lista._id) }
            <hr />
            <h6 className="mb-0">Características</h6>
            </Card.Body>
            <ListGroup variant="flush">
                {
                    lista.beneficios.map(ben => {
                        return <ListGroup.Item><span className="mb-0" style={{ fontSize: 12 }}>{ben.title}</span></ListGroup.Item>
                    })
                }
            </ListGroup>
        </Card>
        </Col>
    }) }
    </Row>
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }

    cargandoScreen(){
        return <Container>  
        <Row className="justify-content-md-center">
            <Col md={4} className="centerv text-center">
            <Spinner animation="grow" />
            </Col>
        </Row>        
    </Container>
    }

    accionesMesa(){
        const { facturando, mesa_cerrar } = this.state
        if(mesa_cerrar.facturado) return false
        if(facturando) return <Spinner animation='grow' />
        return <Row className='mt-3'>
        <Col md={6}><Button style={{ width: "100%" }} onClick={() => this.cerrarMesa(mesa_cerrar._id, true)}>FACTURAR</Button></Col>
        <Col md={6}><Button  style={{ width: "100%", background: "red" }} onClick={() => this.cerrarMesa(mesa_cerrar._id, false)}>CERRAR SIN FACTURAR</Button></Col>
    </Row>
    }

    handleSelectVendedor(id){
        const { mesa_cerrar } = this.state
        mesa_cerrar.id_vendedor = id.value
        return this.setState({ mesa_cerrar })
    }

    modalCerrarMesa(){
        const { mesa_cerrar, pedidos, establecimiento, tipos_pago_activos, user } = this.state

        const pedidos_filtrados = pedidos.filter(pe=>pe.mesa_seleccionada._id===mesa_cerrar._id)
        let calcular_total = 0
        if(pedidos_filtrados.length > 0){
                calcular_total = pedidos_filtrados.reduce((prev,next) => {
                const calcular_total_productos = next.productos.reduce((ant,des) => {
                                return ant + des.precio_final
                }, 0)
                return prev + calcular_total_productos
            }, 0)
        }

        return <Modal show={mesa_cerrar ? true : false } onHide={()=>this.handleClose()} centered 
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header closeButton>
          <Modal.Title>MESA {mesa_cerrar.id}</Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <h5>${Intl.NumberFormat("es-ES").format(calcular_total)}   </h5>
                <label className='form-control-label'>Tipo de pago</label>
                <select className='form-control' name="tipo_pago" onChange={this.handleChangeMetodoPago}>
                    <option value="">Seleccione</option>
                    {
                        tipos_pago_activos.map(tipo => {
                            return <option key={tipo._id} value={tipo.title}>{tipo.title}</option>
                        })
                    }
                </select>

                <CajasSelector default_value={mesa_cerrar.id_vendedor} token={user._id} handleSelectVendedor={(id) => this.handleSelectVendedor(id)} />

                <label className='form-control-label d-block mt-3'>Email cliente {`(opcional para recibir boleta por email)`}</label>
                <input className='form-control' name="email_cliente" onChange={this.handleChangeMetodoPago} />

                {this.accionesMesa()}
                {
                    mesa_cerrar.defontana ? <div>
                        <ImprimirTicket logotipo={establecimiento.establecimiento.logotipo} mesa={mesa_cerrar.defontana} ref={el => (this.componentRef = el)} />
                        { /* <ReactToPrint
                        <div className='text-center'>
                            trigger={() => {
                                return <Button size="sm" variant='success'>IMPRIMIR</Button>
                            }}
                            content={() => this.componentRef}
                            pageStyle={pageStyle}
                            />
                        </div>
                        */}
                    </div> : false
                }
            </Modal.Body>
      </Modal>
    }


    
    render(){
        const { user, color, tipo_visualizacion, loadingMaster, establecimiento } = this.state
        if(loadingMaster) return this.cargandoScreen()
        if(!establecimiento) return <Header color={color} /> 

        return(
            <div className="fluid">
            <Header color={color} logotipo={establecimiento.establecimiento.logotipo} />
            <div className="container-fluid">
                <div className="row">
                    <main className="col-md-12 ms-sm-auto col-lg-12 px-md-4 leftauto">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2">
                    </div>
                    <Row>
                        <Col xs={6}><h1 className="h2 mb-4"> Comandas</h1></Col>
                        <Col xs={6} className="text-right">
                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                            <Tooltip >
                                                Ver como mesas
                                            </Tooltip>
                                            }
                                            ><i class={`fas fa-th mr-3 ${tipo_visualizacion === "mesas" ? 'text-primary' : ''}`} style={{ fontSize: 22  }} onClick={() => this.setState({ tipo_visualizacion: "mesas" })}></i>
                                        </OverlayTrigger>
                        <OverlayTrigger
                                            placement={'top'}
                                            overlay={
                                            <Tooltip >
                                                Ver como estados
                                            </Tooltip>
                                            }
                                            ><i class={`fas fa-columns ${tipo_visualizacion === "estados" ? 'text-primary' : ''}`} style={{ fontSize: 22 }} onClick={() => this.setState({ tipo_visualizacion: "estados" })}></i>
                                        </OverlayTrigger>
                        
                        
                        </Col>
                    </Row>
                    {this.modalCerrarMesa()}
                    {this.modalPedidoSeleccionado()}
                    {this.mostrarPedidos()}
                    </main>
                </div>
</div>
    </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

export default connect(mapStateToProps)(Mesas);