import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { async_storage_key } from '../../lib/backend/data';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        localStorage.getItem(async_storage_key)
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)